import { useQuery } from '@tanstack/react-query';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { FormType } from '../../../../models/FormTypes';
import { currentClientAtom, currentTenantIdAtom } from '../../../../recoil/atoms/Clients';
import PickListService from '../../../../services/PickListService';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import RiskProperties from '../../../form-builder/RiskProperties';
import { Item } from '../../../shared/form-control/DropdownDefaultComponents';
import DropdownSelect from '../../../shared/form-control/DropdownSelect';
import { OpenInNewTabValueRendererWrapper } from '../../../shared/form-control/DropdownSharedComponnets';
import ActionEditorBase from '../ActionEditorBase';
import { createPickListSource } from '../PickListSource';
import TranslatableInput from '../../../shared/form-control/TranslatableInput';

const PickListEditor: FC<EditorProps> = (props) => {
  const { action, patchAction, patchData, form, patchTranslations } = props;
  const { translations } = action;
  const { sourceType, buttonText } = action?.data || {};

  const { t } = useTranslation(['form-builder', 'organisation']);

  const client = useRecoilValue(currentClientAtom);
  const currentTenantId = useRecoilValue(currentTenantIdAtom);

  const {
    data: picklistSources = [],
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['picklists', action.id],
    queryFn: () =>
      PickListService.getAll({ pageSize: 500, pageNumber: 1, clientId: client?.id, includeArchived: true }).then((res) => {
        return createPickListSource(res.data, t);
      }),
  });

  useEffect(() => {
    if (!buttonText) {
      patchData({ buttonText: t('form-builder:action-properties.picklist.form.default-button') });
    }
  }, [buttonText, patchData, t]);

  const onSourceTypeChanged = (option: Item) => {
    patchData({ sourceType: option.id });
  };

  const picklistLinkProvider = useCallback(
    (item: Item) => {
      return item.value ? `/clients/${item.value}/picklists/${item.id}` : `/builder/${currentTenantId}/picklists/${item.id}`;
    },
    [currentTenantId],
  );

  const selectedPicklistSource = picklistSources.flatMap((x) => x.options).find((x) => x.id === sourceType);
  return (
    <div data-cy="picklist-editor">
      <ActionEditorBase {...props}>
        <TranslatableInput
          data-cy="button-text"
          placeholder={t('form-builder:action-properties.picklist.form.button-text')}
          label={t('form-builder:action-properties.picklist.form.button-text')}
          translationKey="buttonText"
          translations={translations ?? {}}
          onTranslationsChange={patchTranslations}
        />

        <DropdownSelect
          data-cy="source"
          className={`${!sourceType && 'mt-8'}`}
          placeholder={t('form-builder:action-properties.picklist.form.picklist-source')}
          label={t('form-builder:action-properties.picklist.form.picklist-source')}
          value={{
            id: sourceType,
            text: selectedPicklistSource?.text || '',
            value: selectedPicklistSource?.value || '',
            disabled: selectedPicklistSource?.disabled,
          }}
          options={picklistSources}
          onChange={onSourceTypeChanged}
          customListRenderer={OpenInNewTabValueRendererWrapper(picklistLinkProvider)}
          onOpen={refetch}
          isFetching={isFetching}
          searchable
        />
      </ActionEditorBase>

      <ConditionsProperties {...props} />

      <PreviewProperties {...props} enableDocumentNumbering />

      {(form.type === FormType.Asset || form.type == FormType.SubForm || form.type == FormType.SubFormWithApproval) && (
        <RiskProperties action={action} patchAction={patchAction} form={form} />
      )}
    </div>
  );
};

export default PickListEditor;
